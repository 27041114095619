:root {
  --toastify-color-light: #E3FCEF;
  --toastify-color-dark: #ffffff;
  --toastify-color-info: #3498db;
  --toastify-color-success: #E3FCEF;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #e74c3c;

  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);

  --toastify-toast-width: 320px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 30px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;

  --toastify-text-color-light: #000;
  --toastify-text-color-dark: #000;

  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #000;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;

  --toastify-spinner-color: #fff;
  --toastify-spinner-color-empty-area: #e0e0e0;


  --toastify-color-progress-success: linear-gradient(
    to right,
    #4cd964,
    #5ac8fa,
    #007aff,
    #34aadc,
    #5856d6,
    #ff2d55
  );

  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-light: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}
