
.normal .ck.ck-editor__editable_inline {
    border: 1px solid var(--ck-color-toolbar-border);
    border-top: 0px;
    border-radius: 0 !important;
}

.error .ck.ck-editor__editable_inline {
    border: 1px solid var(--ck-color-toolbar-border);
    border-bottom: 2px solid #cd003d !important;
    border-radius: 0 !important;
}
